import { config } from '@config';
import {
  generateHeaderAppSwitcherItems,
  HeaderAppItem,
  HeaderAppSwitcherUrls
} from '@xq/ui-kit';

export const generateHeaderAppItems = (
  isOrganizationAdmin?: boolean,
  isXQAdmin?: boolean
): HeaderAppItem[] => {
  const headerAppUrls: HeaderAppSwitcherUrls = {
    homeFrontendUrl: config.homeFrontendUrl,
    insightsFrontendUrl: config.insightsFrontendUrl,
    administrationFrontendUrl: config.administrationFrontendUrl,
    omniFrontendUrl: config.omniFrontendUrl
  };

  return generateHeaderAppSwitcherItems(
    headerAppUrls,
    isOrganizationAdmin,
    isXQAdmin
  );
};
