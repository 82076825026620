import {
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  Col,
  EmptyState,
  getLicenseNameByUUID,
  LicenseApp,
  OmniInfoLicenseCard,
  openStatusNotification,
  Row,
  Typography
} from '@xq/ui-kit';
import React, {
  FC,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import styles from './Licenses.module.scss';
import { useTranslation } from 'react-i18next';
import {
  LicenseProduct,
  LicensesService,
  LicensesServiceApi
} from './licenses-service';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  getBreadcrumbWithDropdown,
  getStatusNotificationTranslations,
  ORGANIZATION_SIDEMENUS
} from '@services';
import { getRouteUrl, ROUTES } from '@router';
import { SidemenuContext, SidemenuContextData } from '@context';

export const Licenses: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const service: LicensesService = new LicensesServiceApi();

  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);

  const [licenseProducts, setLicenseProducts] = useState<LicenseProduct[]>([]);
  const [canCreateNewLicenses, setCanCreateNewLicenses] =
    useState<boolean>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function fetchData() {
    setIsLoading(true);
    try {
      const result = await service.fetchData(params.id);
      setLicenseProducts(result?.licenseProducts);
      setCanCreateNewLicenses(result?.canCreateNewLicenses);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [params]);

  const redirectToLicense = (license: LicenseProduct) => {
    navigate(
      getRouteUrl(ROUTES.ORGANIZATIONS.LICENSE, {
        id: params.id,
        licenseId: license?.license?.uuid
      })
    );
  };

  async function createLicense() {
    navigate(
      getRouteUrl(ROUTES.ORGANIZATIONS.CREATE_LICENSES, { id: params.id })
    );
  }

  const breadcrumbs: BreadcrumbsItem[] = useMemo(
    () => [
      {
        label: t(ROUTES.ORGANIZATIONS.MAIN),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.MAIN)
      },
      getBreadcrumbWithDropdown(
        t,
        sidemenuContext,
        ROUTES.ORGANIZATIONS.LICENSES,
        { id: params.id }
      )
    ],
    [sidemenuContext, params]
  );

  /** Sidemenu context */
  useEffect(() => {
    sidemenuContext.setActiveMenu(ORGANIZATION_SIDEMENUS.ORGANIZATION_LICENSES);
  }, [sidemenuContext]);

  return (
    <Fragment>
      <Row cols={10}>
        <Breadcrumbs
          NavLink={NavLink}
          className="breadcrumbs"
          items={breadcrumbs}
        />

        <Col col={9} className="heading">
          <Typography element="div" variant="h2">
            {t('common.licenses')}
          </Typography>
          {licenseProducts.length > 0 && (
            <Button
              type="third"
              icon="licenses-add"
              disabled={!canCreateNewLicenses}
              onClick={createLicense}
            >
              {t('common.create')}
            </Button>
          )}
        </Col>

        {licenseProducts && licenseProducts?.length > 0 && (
          <Col col={9} className={styles.licenses}>
            {licenseProducts?.map((licenseProduct) => {
              return (
                <OmniInfoLicenseCard
                  className={styles.license}
                  key={licenseProduct?.license?.uuid}
                  translations={{
                    licenseFeatures: t('common.licenseFeatures'),
                    trialVersion: t('uiKit.trialVersion'),
                    expiryDate: t('uiKit.expiryDate')
                  }}
                  app={getLicenseNameByUUID(licenseProduct?.uuid) as LicenseApp}
                  description={licenseProduct?.description}
                  licenseFeatures={licenseProduct?.license?.licenseFeatureNames}
                  isTrial={licenseProduct?.license?.isTrial}
                  licenseType={licenseProduct?.license?.licenseTypeName}
                  expiryDate={licenseProduct?.license?.expireAt}
                  onClick={() => redirectToLicense(licenseProduct)}
                  isLoading={isLoading}
                />
              );
            })}
          </Col>
        )}

        {!isLoading && (!licenseProducts || licenseProducts.length === 0) && (
          <EmptyState
            heading={t('uiKit.oopsItIsEmpty')}
            description={t('alerts.looksLikeTheOrganizationHasNoLicenses')}
            onClick={createLicense}
            buttonText={t('common.create')}
            buttonIcon={'licenses-add'}
            isCentered={true}
          />
        )}
      </Row>
    </Fragment>
  );
};

Licenses.displayName = 'Licenses';
