import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import {
  Header,
  HeaderApp,
  HeaderAppNames,
  NavItem,
  NotificationContainer,
  offsets,
  openStatusNotification,
  ScrollTopButton
} from '@xq/ui-kit';
import styles from './Layout.module.scss';
import { NavLink, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LayoutService, LayoutServiceApi } from './layout-service';
import { config } from '@config';
import { getRouteUrl, ROUTES } from '@router';
import { UserContext, UserContextData } from '@context';
import {
  getStatusNotificationTranslations,
  redirectToSSOLoginPage
} from '@services';
import { LayoutData } from './dataTypes';
import ScrollToTop from '@services/utils';

const currentApp: HeaderApp = HeaderAppNames.Omni;

export const Layout: FC = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const service: LayoutService = new LayoutServiceApi();

  const [userData, setUserData] = useState<LayoutData>(null);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [avatar, setAvatar] = useState<string>('');
  const [languageIso2, setLanguageIso2] = useState<string>('');
  const [isAuthChecked, setIsAuthChecked] = useState<boolean>(false);
  const [mimicryOrganizationName, setMimicryOrganizationName] =
    useState<string>(null);

  const fullName = useMemo(() => {
    let name = '';
    if (firstName) {
      name += `${firstName} `;
    }
    if (lastName) {
      name += lastName;
    }
    if (!name) {
      name = 'Username';
    }
    return name;
  }, [firstName, lastName]);

  const headerNavItems: NavItem[] = useMemo(() => {
    const routeIds = [
      ROUTES.HOME,
      ROUTES.ORGANIZATIONS.MAIN,
      ROUTES.LICENSES_FEATURES.MAIN,
      ROUTES.MODEL_TREE.MAIN,
      ROUTES.MIMICRY.MAIN
    ];

    let routes = routeIds.map((route) => {
      return {
        id: route,
        name: t(route),
        path: getRouteUrl(route)
      };
    });

    routes = routes?.map((route) => {
      if (route.id === ROUTES.MIMICRY.MAIN) {
        return {
          ...route,
          name: mimicryOrganizationName
            ? (route.name += `: ${mimicryOrganizationName}`)
            : route.name,
          alwaysActiveMode: {
            enabled: Boolean(mimicryOrganizationName),
            icon: 'close',
            buttonAction: stopMimicry
          }
        };
      }
      return route;
    });

    return routes;
  }, [mimicryOrganizationName]);

  const profileLinks: NavItem[] = [
    {
      name: t('routes.userSettings'),
      path: config.accountFrontendUrl
    }
  ];

  const initValues = () => {
    setFirstName(userData?.firstName);
    setLastName(userData?.lastName);
    setAvatar(userData?.avatar);
    setLanguageIso2(userData?.languageIso2);
    setMimicryOrganizationName(userData?.mimicryOrganizationName);
  };

  async function authCheck() {
    try {
      await service.authCheck();
      setIsAuthChecked(true);
    } catch (error) {
      setIsAuthChecked(false);
      switch (error?.error) {
        case 'IAM_UNAUTHORIZED': {
          redirectToSSOLoginPage();
          return;
        }
        default: {
          openStatusNotification({
            translations: getStatusNotificationTranslations(t),
            status: error?.status,
            error: {
              details: error?.details,
              code: error?.error,
              message: error?.message
            }
          });
        }
      }
    }
  }

  async function fetchData() {
    try {
      const res = await service.fetchData();
      setUserData(res);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function stopMimicry() {
    try {
      await service.stopMimicry();
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: t('notifications.youTurnedOffMimicryMode')
      });
      setMimicryOrganizationName(null);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function logout() {
    try {
      await service.logout();
      redirectToSSOLoginPage();
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  useEffect(() => {
    authCheck().then(() => {
      fetchData();
    });
  }, []);

  useEffect(() => {
    initValues();
  }, [userData]);

  const userContextData: UserContextData = {
    firstName,
    lastName,
    avatar,
    languageIso2,
    mimicryOrganizationName,
    setFirstName,
    setLastName,
    setAvatar,
    setLanguageIso2,
    setMimicryOrganizationName
  };

  useEffect(() => {
    if (languageIso2) {
      i18n.changeLanguage(languageIso2);
      if (localStorage.getItem('lang') !== languageIso2) {
        localStorage.setItem('lang', languageIso2);
      }
    }
  }, [languageIso2]);

  return (
    <Fragment>
      {isAuthChecked && (
        <div className={styles.layout}>
          <Header
            className={styles.header}
            apps={userData?.apps}
            navItems={headerNavItems}
            profileLinks={profileLinks}
            avatar={avatar}
            organizations={userData?.organizations}
            currentOrganizationId={userData?.currentOrganizationId}
            name={fullName}
            currentApp={currentApp}
            NavLink={NavLink}
            onSignOut={logout}
            translations={{
              signOut: t('routes.signOut')
            }}
          />
          <main className={offsets['mb-40']}>
            <ScrollToTop />
            <UserContext.Provider value={userContextData}>
              <Outlet />
            </UserContext.Provider>
          </main>
          <NotificationContainer />
        </div>
      )}
      <ScrollTopButton />
    </Fragment>
  );
};

Layout.displayName = 'Layout';
