import React, { Fragment } from 'react';
import {
  Chevron,
  colors,
  common,
  Dropdown,
  formatDate,
  formatNumber,
  Icon,
  IconNames,
  offsets,
  TableColumn,
  Tooltip,
  Typography
} from '@xq/ui-kit';
import styles from './Organizations.module.scss';
import { ManagerList } from '@pages/Organizations/Organizations/dataTypes';
import { TFunction } from 'i18next';
import type {
  OrganizationLicenseInfoDTOResponse,
  OrganizationListItemDTOResponse,
  OrganizationModeltreeInfoDTOResponse
} from '@xq/omni-gateway-frontend-client';
import { DASH } from '@constants';

export function createOrganizationsRows(
  organizations: OrganizationListItemDTOResponse[],
  managers: ManagerList[],
  changeManager: (rowId: string, newManagerValue: string) => void,
  t: TFunction
) {
  return organizations?.map((row) => ({
    id: row.uuid,
    data: {
      name: (
        <Fragment>
          <Typography
            element="div"
            variant="body-5"
            className={common['no-text-transform']}
          >
            {row.name}
          </Typography>
          {row.description && (
            <Typography
              element="div"
              variant="body-6"
              className={styles['description-row']}
            >
              {row.description}
            </Typography>
          )}
        </Fragment>
      ),
      countryIso3: row.countryIso3,
      createdAt: formatDate(row.createdAt),
      licenses: (
        <div className={styles['license-rows']}>
          {row.licenses?.map((license, index) => (
            <div key={index} className={styles['license-row']}>
              {warningForLicense(license, t) && (
                <Tooltip
                  className={styles['warning-icon']}
                  type="bottom"
                  content={warningForLicense(license, t)?.message}
                >
                  <Icon
                    color={warningForLicense(license, t)?.type}
                    name={warningForLicense(license, t).icon}
                    size={'s'}
                  />
                </Tooltip>
              )}

              <div>
                <Typography
                  key={index}
                  style={license.isTrial ? { marginRight: '10px' } : {}}
                  variant="body-4"
                  color={
                    warningForLicense(license, t)
                      ? warningForLicense(license, t).color
                      : colors.gray100Color
                  }
                >
                  {license.name}
                </Typography>
                {license.isTrial && (
                  <Chevron style={{ display: 'inline-block' }}>
                    {t('common.trial')}
                  </Chevron>
                )}
              </div>
            </div>
          ))}
        </div>
      ),
      usersAmount: (
        <Typography variant="number-1">
          {formatNumber(row.usersAmount)}
        </Typography>
      ),
      // todo show we will have mtDatabaseVersion
      mtDatabaseVersion: (
        <div className={styles['db-row']}>
          {showWarningForDatabase(row.modeltreeInfo) && (
            //todo create message
            <Tooltip
              className={styles['warning-icon']}
              type="bottom"
              content={"Client's ModelTree need to be updated"}
            >
              <Icon color={'warning'} name={'alert'} size={'s'}></Icon>
            </Tooltip>
          )}
          <Typography
            variant="number-1"
            color={
              showWarningForDatabase(row.modeltreeInfo)
                ? colors.goldColor
                : colors.gray100Color
            }
          >
            {row?.modeltreeInfo?.currentAppVersion || DASH}
          </Typography>
          <Typography variant="number-1" className={offsets['mx-8']}>
            /
          </Typography>
          <Typography variant="number-1">
            {row?.modeltreeInfo?.assignedAppVersion || DASH}
          </Typography>
        </div>
      ),
      managerName: (
        <>
          {managers?.length > 0 && (
            <Dropdown
              onValueSelected={(value) => changeManager(row.uuid, value)}
              items={managers}
              selectedItemValue={row.managerUuid}
              variant="simple"
              position="bottom"
            />
          )}
        </>
      )
    }
  }));
}

export const createOrganizationsColumns = (t: TFunction): TableColumn[] => [
  {
    title: t('common.name'),
    key: 'name',
    width: 140,
    alignment: 'left',
    paddingLeft: 20,
    sortable: true
  },
  {
    title: t('common.country'),
    key: 'countryIso3',
    width: 50,
    alignment: 'left',
    paddingLeft: 20,
    paddingRight: 20,
    sortable: true
  },
  {
    title: t('common.createOn'),
    key: 'createdAt',
    width: 100,
    alignment: 'left',
    paddingLeft: 20,
    paddingRight: 20,
    sortable: true
  },
  {
    title: t('common.licenses'),
    key: 'licenses',
    width: 160,
    alignment: 'left',
    paddingLeft: 40,
    sortable: false
  },
  {
    title: t('common.users'),
    key: 'usersAmount',
    width: 90,
    alignment: 'right',
    paddingLeft: 20,
    paddingRight: 20,
    sortable: true
  },
  {
    title: t('organizations.mtVersionDatabaseAssigned'),
    key: 'mtDatabaseVersion',
    width: 170,
    alignment: 'right',
    paddingLeft: 20,
    paddingRight: 20,
    sortable: false
  },
  {
    title: t('common.organizationManager'),
    key: 'managerName',
    width: 160,
    alignment: 'left',
    paddingLeft: 20,
    sortable: true
  }
];

interface LicenseStatus {
  message: string;
  type: 'warning' | 'error';
  icon: IconNames;
  color: string;
}

const warningForLicense = (
  license: OrganizationLicenseInfoDTOResponse,
  t: TFunction
): LicenseStatus => {
  if (!license) {
    return null;
  }

  const getLicenseDataForType = (
    type: 'warning' | 'error'
  ): { type: 'warning' | 'error'; icon: IconNames; color: string } => {
    switch (type) {
      case 'warning': {
        return {
          type: type,
          icon: 'alert',
          color: colors.goldColor
        };
      }
      case 'error': {
        return {
          type: type,
          icon: 'info',
          color: colors.red70Color
        };
      }
    }
  };

  if (license.isExpired) {
    return {
      ...getLicenseDataForType('error'),
      message: t('common.expired')
    };
  }

  if (license.willExpireInDays) {
    return {
      ...getLicenseDataForType('warning'),
      message: `Will expire in ${license.willExpireInDays} day${
        license.willExpireInDays > 1 ? 's' : ''
      }`
    };
  }
  if (license.isSuspended) {
    return {
      ...getLicenseDataForType('warning'),
      message: t('common.suspended')
    };
  }
  return null;
};

const showWarningForDatabase = (
  modeltreeInfo: OrganizationModeltreeInfoDTOResponse
): boolean => {
  if (!modeltreeInfo?.currentAppVersion || !modeltreeInfo?.assignedAppVersion) {
    return false;
  }
  return !(
    modeltreeInfo?.currentAppVersion == modeltreeInfo?.assignedAppVersion
  );
};
