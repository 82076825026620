import { TFunction } from 'i18next';

export const MODEL_TREE_DESKTOP_UUID = 'afd851cb-bb8e-4b16-9f4f-597445b0177f';

export const userTableBlockTranslations = (t: TFunction) => ({
  external: t('organizations.external'),
  azureAD: t('organizations.azureAD'),
  admin: t('common.admin'),
  deleted: t('common.deleted')
});
