import {
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  Col,
  display,
  flex,
  getLicenseNameByUUID,
  LicenseApp,
  offsets,
  OmniLicenseCard,
  openStatusNotification,
  Row,
  SelectItem,
  Typography
} from '@xq/ui-kit';
import React, {
  FC,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import styles from './EditLicense.module.scss';
import { useTranslation } from 'react-i18next';
import {
  EditLicenseService,
  EditLicenseServiceApi
} from './edit-licenses-service';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  getBreadcrumbWithDropdown,
  getCurrentLanguage,
  getStatusNotificationTranslations,
  submitForm
} from '@services';
import { getRouteUrl, ROUTES } from '@router';
import { SidemenuContext, SidemenuContextData } from '@context';
import cn from 'classnames';
import { LicenseEditInfoDTORequest } from '@xq/omni-gateway-frontend-client';
import { EditLicenseModel, LicenseFeature } from './dataTypes';
//todo update ui kit: card without trash/plus btn

export const EditLicense: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const service: EditLicenseService = new EditLicenseServiceApi();
  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [license, setLicense] = useState<EditLicenseModel>(null);
  const [licenseFullInfo, setLicenseFullInfo] = useState<LicenseFullInfo>(null);

  interface LicenseFullInfo {
    uuid: string;
    name: string;
    app: LicenseApp;
    description: string;
    features: Feature[];
    isTrial: boolean;
    expiryDate: Date;
    isActive: boolean;
    licenseType?: SelectItem;
    licenseTypes?: SelectItem[];
  }

  const generateLicenseFullInfo = (
    licenseProduct: EditLicenseModel
  ): LicenseFullInfo => {
    if (!licenseProduct) {
      return null;
    }
    return {
      uuid: licenseProduct?.license?.uuid,
      name: licenseProduct?.name,
      app: getLicenseNameByUUID(licenseProduct.uuid) as LicenseApp,
      /** License description */
      description: licenseProduct.description,
      /** License features list */
      features: mapFeatures(licenseProduct?.licenseFeatures),
      isTrial: Boolean(licenseProduct?.license?.isTrial),
      expiryDate: licenseProduct?.license?.expireAt
        ? licenseProduct?.license?.expireAt
        : null,
      isActive: Boolean(licenseProduct?.license),
      licenseTypes: licenseProduct?.licenseTypes,
      licenseType: licenseProduct?.license?.licenseType
    };
  };

  useEffect(() => {
    setLicenseFullInfo(generateLicenseFullInfo(license));
  }, [license]);

  const mapFeatures = (features: LicenseFeature[]): Feature[] => {
    if (!features) {
      return [];
    }
    return features?.map((feature) => {
      return {
        id: feature.uuid,
        label: feature.name,
        value: Boolean(feature?.isActive),
        defaultValue: feature?.isActive
      };
    });
  };

  const changeFeature = (
    licenseId: string,
    featureId: string,
    value: boolean
  ) => {
    const features = licenseFullInfo?.features?.map((feature) => {
      if (feature.id === featureId) {
        feature.value = value;
      }
      return feature;
    });

    setLicenseFullInfo({ ...licenseFullInfo, features });
  };

  const setTrial = (licenseId: string, isTrial: boolean) => {
    setLicenseFullInfo({ ...licenseFullInfo, isTrial });
  };

  const setLicenseType = (licenseId: string, value: SelectItem) => {
    setLicenseFullInfo({ ...licenseFullInfo, licenseType: value });
  };

  const resetFeatures = () => {
    const features = licenseFullInfo?.features?.map((feature) => {
      feature.value = Boolean(feature?.defaultValue);
      return feature;
    });

    setLicenseFullInfo({ ...licenseFullInfo, features });
  };

  const setExpiryDate = (licenseId: string, date: Date) => {
    setLicenseFullInfo({ ...licenseFullInfo, expiryDate: date });
  };

  const setIsActive = (licenseId: string, isActive: boolean) => {
    setLicenseFullInfo({ ...licenseFullInfo, isActive });
  };

  interface Feature {
    id: string;
    label: string;
    value: boolean;
    defaultValue: boolean;
  }

  async function fetchData() {
    try {
      setIsLoading(true);
      const result = await service.fetchData(params.id, params.licenseId);
      setLicense(result);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [params.licenseId]);

  const isLicensesValid = useMemo(() => {
    return (
      licenseFullInfo?.isActive &&
      (!licenseFullInfo?.isTrial ||
        (licenseFullInfo.isTrial && licenseFullInfo.expiryDate))
    );
  }, [licenseFullInfo]);

  const cancel = () => {
    navigate(
      getRouteUrl(ROUTES.ORGANIZATIONS.LICENSE, {
        id: params.id,
        licenseId: params?.licenseId
      })
    );
  };

  async function save() {
    try {
      const licenseData: LicenseEditInfoDTORequest = {
        isTrial: licenseFullInfo?.isTrial,
        licenseTypeUuid: licenseFullInfo?.licenseType?.value,
        licenseProductFeatureUUIDs:
          licenseFullInfo?.features?.length > 0
            ? licenseFullInfo?.features
                ?.filter((el) => el.value)
                .map((el) => el.id)
            : null
      };
      if (licenseFullInfo?.expiryDate) {
        licenseData.expireAt = new Date(licenseFullInfo?.expiryDate);
      }
      setIsLoading(true);
      await service.save(params.id, licenseFullInfo?.uuid, licenseData);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200
      });
      navigate(
        getRouteUrl(ROUTES.ORGANIZATIONS.LICENSE, {
          id: params.id,
          licenseId: params?.licenseId
        })
      );
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  const breadcrumbs: BreadcrumbsItem[] = useMemo(() => {
    return [
      {
        label: t(ROUTES.ORGANIZATIONS.MAIN),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.MAIN)
      },
      getBreadcrumbWithDropdown(
        t,
        sidemenuContext,
        ROUTES.ORGANIZATIONS.LICENSES,
        { id: params?.id }
      ),
      {
        label: t(ROUTES.ORGANIZATIONS.LICENSES),
        url: getRouteUrl(ROUTES.ORGANIZATIONS.LICENSES, {
          id: sidemenuContext?.currentOrganization?.uuid
        })
      },
      {
        label: licenseFullInfo?.name,
        url: getRouteUrl(ROUTES.ORGANIZATIONS.LICENSE, {
          id: params.id,
          licenseId: params.licenseId
        })
      }
    ];
  }, [licenseFullInfo, sidemenuContext, params]);

  /** Sidemenu context */
  useEffect(() => {
    sidemenuContext.setActiveMenu(
      `licenses-${sidemenuContext?.licenses?.find(
        (el) => el?.uuid === params.licenseId
      )?.uuid}`
    );
  }, [sidemenuContext.licenses, params?.licenseId]);

  return (
    <Fragment>
      <Row cols={10}>
        <Breadcrumbs
          NavLink={NavLink}
          className="breadcrumbs"
          items={breadcrumbs}
        />

        <Col col={9} className="heading">
          <Typography element="div" variant="h2">
            {t('organizations.editLicense')}
          </Typography>
        </Col>

        <form onSubmit={submitForm}>
          <Col col={9} className={styles.licenses}>
            {licenseFullInfo && (
              <OmniLicenseCard
                className={styles.license}
                translations={{
                  expiryDate: t('uiKit.expiryDate'),
                  isTrial: t('uiKit.isTrial'),
                  licenseFeatures: t('common.licenseFeatures')
                }}
                app={licenseFullInfo?.app}
                description={licenseFullInfo?.description}
                features={licenseFullInfo?.features}
                onChangeFeature={(featureId: string, value: boolean) =>
                  changeFeature(licenseFullInfo?.uuid, featureId, value)
                }
                resetFeatures={resetFeatures}
                isTrial={licenseFullInfo?.isTrial}
                setTrial={(value) => setTrial(licenseFullInfo?.uuid, value)}
                locale={getCurrentLanguage()}
                expiryDate={licenseFullInfo?.expiryDate}
                setExpiryDate={(value) =>
                  setExpiryDate(licenseFullInfo?.uuid, value)
                }
                isActive={licenseFullInfo?.isActive}
                setIsActive={(value) =>
                  setIsActive(licenseFullInfo?.uuid, value)
                }
                licenseTypeLabel={t('uiKit.licenseType')}
                licenseType={licenseFullInfo?.licenseType}
                licenseTypes={licenseFullInfo?.licenseTypes}
                setLicenseType={(value) =>
                  setLicenseType(licenseFullInfo?.uuid, value)
                }
                useRemove={false}
              />
            )}
          </Col>

          <div className={offsets['mt-40']}>
            <div className={cn(display['d-flex'], flex['align-items-center'])}>
              <Button
                buttonType={'submit'}
                disabled={!isLicensesValid}
                onClick={save}
                className={offsets['mr-20']}
                isLoading={isLoading}
              >
                {t('common.save')}
              </Button>
              <Button onClick={cancel} type="secondary" disabled={isLoading}>
                {t('common.cancel')}
              </Button>
            </div>
          </div>
        </form>
      </Row>
    </Fragment>
  );
};

EditLicense.displayName = 'EditLicense';
