import {
  Button,
  Col,
  offsets,
  openStatusNotification,
  Row,
  Select,
  SelectItem,
  Typography
} from '@xq/ui-kit';
import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MimicryService, MimicryServiceApi } from './mimicry-service';
import { getStatusNotificationTranslations, submitForm } from '@services';
import { UserContext, UserContextData } from '@context';
import { useNavigate } from 'react-router-dom';
import { getRouteUrl, ROUTES } from '@router';

export const Mimicry: FC = () => {
  const { t } = useTranslation();
  const service: MimicryService = new MimicryServiceApi();

  const userContext: UserContextData = useContext(UserContext);
  const navigate = useNavigate();

  const [organizations, setOrganizations] = useState<SelectItem[]>(null);
  const [modeltreeVersionForOrganization, setModeltreeVersionForOrganization] =
    useState<string>('');
  const [selectedOrganization, setSelectedOrganization] =
    useState<SelectItem>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function fetchData() {
    try {
      const response = await service.fetchData();
      setOrganizations(response?.organizations);
      setSelectedOrganization(response?.selectedMimicryOrganization);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!userContext?.mimicryOrganizationName) {
      setSelectedOrganization(null);
    }
  }, [userContext?.mimicryOrganizationName]);

  const isFieldsFilled = (): boolean => {
    return !selectedOrganization;
  };

  useEffect(() => {
    getModeltreeVersionForOrganization(selectedOrganization);
  }, [selectedOrganization?.value]);

  async function getModeltreeVersionForOrganization(
    selectedOrganization: SelectItem
  ) {
    if (!selectedOrganization?.value) {
      setModeltreeVersionForOrganization('');
      return;
    }

    try {
      const version = await service.getModeltreeVersionForOrganization(
        selectedOrganization?.value
      );

      setModeltreeVersionForOrganization(version);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function mimicry() {
    setIsLoading(true);

    try {
      await service.mimicry(selectedOrganization);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: (
          <span>
            {t('notifications.youTurnedOnMimicryModeUnderOrganization')}{' '}
            <b>{selectedOrganization.label}</b>
          </span>
        )
      });
      userContext.setMimicryOrganizationName(selectedOrganization.label);
      navigate(
        getRouteUrl(ROUTES.ORGANIZATIONS.ORGANIZATION, {
          id: selectedOrganization.value
        })
      );
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function stopMimicry() {
    setIsLoading(true);

    try {
      await service.stopMimicry();
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: t('notifications.youTurnedOffMimicryMode')
      });
      userContext.setMimicryOrganizationName(null);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Fragment>
      <Row cols={10}>
        <Col col={10}>
          <Typography className={offsets['mb-40']} element="div" variant="h2">
            {t('routes.mimicry')}
          </Typography>
        </Col>
      </Row>

      <Row cols={10}>
        <Col col={8} md={4}>
          <form onSubmit={submitForm}>
            <Select
              disabled={!!userContext?.mimicryOrganizationName}
              items={organizations}
              selected={selectedOrganization}
              onChange={setSelectedOrganization}
              label={t('organizations.organization')}
            />
            {selectedOrganization?.value && (
              <Typography className={offsets['mt-20']} variant={'body-4'}>
                {`${selectedOrganization?.label}  ${t(
                  'modelTree.currentModelTreeVersion'
                )}: `}
                <b>
                  {modeltreeVersionForOrganization || t('common.notDefined')}
                </b>
              </Typography>
            )}

            <div>
              <Button
                className={offsets['mt-40']}
                buttonType={'submit'}
                onClick={
                  userContext?.mimicryOrganizationName ? stopMimicry : mimicry
                }
                isLoading={isLoading}
                disabled={isFieldsFilled()}
              >
                {userContext?.mimicryOrganizationName
                  ? t('common.stop')
                  : t('routes.mimicry')}
              </Button>
            </div>
          </form>
        </Col>
      </Row>
    </Fragment>
  );
};

Mimicry.displayName = 'Mimicry';
